import * as yup from 'yup';

export const resetPassValidator = yup.object({
  newPassword: yup
    .string()
    .min(8, 'Password must contain at least 8 symbols')
    .required('Field is Required'),
  confirmPassword: yup
    .string()
    .required('Please re-type your password')
    // use oneOf to match one of the values inside the array.
    // use "ref" to get the value of passwrod.
    .oneOf([yup.ref('newPassword')], 'Passwords does not match'),
});

