import { styled } from '@mui/material/styles';
import { TreeItem, treeItemClasses } from '@mui/lab';
import { getCustomThemeStyles } from '../../styles/theme';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface ITreeHeaderProps {
  labelText: string | React.ReactNode;
  nodeId: string;
  className?: string;
  disabled?: boolean;
  children: React.ReactNode;
}

interface IStyledTreeItemProps {
  labelText: string | React.ReactNode;
  className?: string;
  to: string;
  disabled?: boolean;
  state?: any;
  target?: string;
  nodeId: string;
}

export const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.primary,
  [`& .${treeItemClasses.content}`]: {
    fontSize: '20px',
    backgroundColor: theme.palette.mode === 'dark' ? '#151C2B' : '#FEFEFF',
    border: '1px solid #D2D1DA',
    width: '264px',
    color: theme.palette.mode === 'dark' ? '#FEFEFF' : '#002147',
    borderRadius: '10px',
    fontWeight: 400,
    height: '50px',
    transition: '0.15s',

    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#36DFD3' : '#802FF3',
      border:
        theme.palette.mode === 'dark'
          ? '1px solid #36DFD3'
          : '1px solid #802FF3',
      color: theme.palette.mode === 'dark' ? '#002147' : '#FEFEFF',
    },

    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: theme.palette.mode === 'dark' ? '#36DFD3' : '#802FF3',
      border:
        theme.palette.mode === 'dark'
          ? '1px solid #36DFD3'
          : '1px solid #802FF3',
      color: theme.palette.mode === 'dark' ? '#002147' : '#FEFEFF',
      boxShadow: getCustomThemeStyles(theme.palette.mode === 'dark').shadows
        .main,
    },
    [`& .${treeItemClasses.label}`]: {
      fontSize: '20px',
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    [`& .${treeItemClasses.content}`]: {
      width: '238px',
      height: '42px',

      '&.Mui-selected': {
        boxShadow: getCustomThemeStyles(theme.palette.mode === 'dark').shadows
          .main,
        backgroundColor: theme.palette.mode === 'dark' ? '#151C2B' : '#FEFEFF',
        color: theme.palette.mode === 'dark' ? '#FEFEFF' : '#002147',
      },

      '&:hover': {
        boxShadow: getCustomThemeStyles(theme.palette.mode === 'dark').shadows
          .main,
        backgroundColor: theme.palette.mode === 'dark' ? '#151C2B' : '#FEFEFF',
        color: theme.palette.mode === 'dark' ? '#FEFEFF' : '#002147',
      },
    },
  },

  '&.withoutSpace': {
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: '24px',

      '& span': {
        color: theme.palette.primary.main,
      },

      '&:hover span': {
        color: theme.palette.text.primary,
      },
    },
    '& .MuiTreeItem-iconContainer': {
      width: 0,
      marginRight: 0,
    },
  },
}));

export const StyledTreeHeader = ({
  labelText,
  disabled,
  nodeId,
  children,
  ...other
}: ITreeHeaderProps) => (
  <Box
    sx={{ display: 'flex', alignItems: 'center' }}
    style={{ textDecoration: 'none', color: 'inherit' }}
  >
    <StyledTreeItemRoot
      sx={{ margin: '24px 0 0 8px' }}
      disabled={disabled}
      nodeId={nodeId}
      label={
        <Typography
          variant="body2"
          sx={{ fontWeight: 'inherit', fontSize: 'inherit', flexGrow: 1 }}
        >
          {labelText}
        </Typography>
      }
      {...other}
    >
      {children}
    </StyledTreeItemRoot>
  </Box>
);

export const StyledTreeItem = ({
  labelText,
  to,
  disabled,
  state,
  target,
  ...other
}: IStyledTreeItemProps) => {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center' }}
      state={state}
      to={to}
      target={target}
      component={Link}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <StyledTreeItemRoot
        sx={{ margin: '8px 0 0 8px' }}
        disabled={disabled}
        label={
          <Typography
            variant="body2"
            sx={{ fontWeight: 'inherit', fontSize: 'inherit', flexGrow: 1 }}
          >
            {labelText}
          </Typography>
        }
        {...other}
      />
    </Box>
  );
};

