import React, { useEffect, useState } from 'react';
import ChooseDialog from '../ui/components/ChooseDialog';
import { useTheme } from '@mui/material';
import { getCustomThemeStyles } from '../ui/styles/theme';
import FileLinkIcon from '../ui/svg/link';

import LoginIcon from '../ui/svg/login';
import { monitoringService } from '../helpers/monitoring';
import { authService } from '../helpers/auth';
import { useNavigate } from 'react-router-dom';

const DownloadKeyDashboard = () => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const token = localStorage.getItem('TOKEN');
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      const timeRemaining = authService.getRemainingTime(token);
      const timeInMinutes = timeRemaining / 60;
      if (timeInMinutes > 0) {
        setIsAuthorized(true);
      } else {
        navigate('/signin', {
          state: { to: '/data-modal', back: '/routers' },
        });
      }

      // authService.checkToken(token).then((res) => {
      //   if (res && res.status === 'success') {
      //     setIsAuthorized(true);
      //   } else {
      //     navigate('/signin', { state: { to: '/pools/data' } });
      //   }
      // });
    } else {
      navigate('/signin', { state: { to: '/data-modal', back: '/routers' } });
    }
  }, [navigate, token]);

  const theme = useTheme();
  const customTheme = getCustomThemeStyles(theme.palette.mode === 'dark');

  const className = 'follow';

  const frameColor =
    className === 'follow' ? '#002147' : theme.palette.text.primary;
  const arrowColor = theme.palette.primary.main;

  const downloadMonitoringToken = async () => {
    const token = localStorage.getItem('TOKEN');
    if (token) {
      const response = await monitoringService.getGrafanaToken(token);
      if (response) {
        const { token: grafana } = response;
        const blob = new Blob([grafana], { type: 'application/json' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = 'kuutamo-monitoring.token';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const data = [
    {
      id: 1,
      className,
      onClick: downloadMonitoringToken,
      title: 'Step 1',
      text: 'Download monitoring token',
      icon: <FileLinkIcon frameColor={frameColor} arrowColor={arrowColor} />,
    },
    {
      id: 2,
      className,
      //   onClick: downloadConfigFile,
      title: 'Step 2',
      text: 'Go to dashboard',
      to: '/dashboard',
      //   disabled: !(isServersExists && isKeysExists),
      icon: <LoginIcon frameColor={frameColor} arrowColor={arrowColor} />,
    },
  ];

  const handleClose = () => {
    navigate('/routers');
  };

  return isAuthorized ? (
    <ChooseDialog
      title="Follow the steps"
      isOpen={true}
      data={data}
      handleClose={handleClose}
      dialogProps={{
        sx: {
          '& .MuiPaper-root': {
            boxShadow: customTheme.shadows.modal,
          },

          '& .MuiBackdrop-root': {
            backgroundColor: 'inherit',
          },
        },
      }}
    />
  ) : null;
};

export default DownloadKeyDashboard;

