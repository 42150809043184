import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Container,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { FocusEvent, KeyboardEvent } from 'react';
import { useState } from 'react';
import TrashIcon from '../ui/svg/trash';
import { useConfirm } from 'material-ui-confirm';
import ModalWrapper from '../ui/components/modals';
import { IKey } from '../types/routes.types';
import { IServer } from '../types/servers.types';
import SnackbarAlert from '../ui/components/SnackbarAlert';

const Keys = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [newNameKey, setNewNameKey] = useState('');
  const [newKey, setNewKey] = useState('');
  const [helperTextName, setHelperTextName] = useState('');
  const [helperTextKey, setHelperTextKey] = useState('');
  const [keys, setKeys] = useState<Array<IKey>>(
    JSON.parse(localStorage.getItem('keys') || '[]')
  );
  const [warningMsg, setWarningMsg] = useState('');
  const [selectedKey, setSelectedKey] = useState<string | boolean>('');
  const confirm = useConfirm();

  const theme = useTheme();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const isSSHPublicKey = (publicKey: string) => {
    const sshPublicKeyRegex =
      /^(?:ssh-rsa AAAAB3NzaC1yc2|ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNT|ecdsa-sha2-nistp384 AAAAE2VjZHNhLXNoYTItbmlzdHAzODQAAAAIbmlzdHAzOD|ecdsa-sha2-nistp521 AAAAE2VjZHNhLXNoYTItbmlzdHA1MjEAAAAIbmlzdHA1Mj|ssh-ed25519 AAAAC3NzaC1lZDI1NTE5|ssh-dss AAAAB3NzaC1kc3)[0-9A-Za-z+/].*\n?$/;
    return sshPublicKeyRegex.test(publicKey);
  };

  const addKey = () => {
    if (!newNameKey) {
      setHelperTextName('This field is required');
    } else if (!newKey || !isSSHPublicKey(newKey)) {
      setHelperTextKey('Please enter a valid public key');
    } else if (keys.filter((element) => element.name === newNameKey)[0]) {
      setHelperTextName('Key with this name already exists');
    } else {
      keys.push({ name: newNameKey, key: newKey });
      localStorage.setItem('keys', JSON.stringify(keys));
      setKeys(keys);
      setNewKey('');
      setNewNameKey('');
      setIsOpen(false);
    }
  };

  const updateKey = (name: string, key: string) => {
    if (isSSHPublicKey(key)) {
      const updatedArray = keys.map((item) => {
        if (item.name === name) {
          return { ...item, key: key };
        }
        return item;
      });
      setKeys(updatedArray);
      localStorage.setItem('keys', JSON.stringify(updatedArray));
    } else {
      setWarningMsg('Public Key is invalid');
    }
  };

  const deleteKey = (name: string) => {
    const updatedArray = keys.filter(function (item) {
      return item.name !== name;
    });
    setKeys(updatedArray);
    localStorage.setItem('keys', JSON.stringify(updatedArray));

    const servers = JSON.parse(
      localStorage.getItem('servers') || '[]'
    ) as Array<IServer>;
    const updatedServers = servers.filter(function (item) {
      if (item.key === name) item.key = '';
      return item;
    });
    localStorage.setItem('servers', JSON.stringify(updatedServers));
  };

  function handleKeyDown(
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) {
    if (event.key === 'Enter' || event.keyCode === 27) {
      updateKey(name, event.currentTarget.value);
      setSelectedKey(false);
    }
  }

  function handleBlur(
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    name: string
  ) {
    if (event.type === 'blur') {
      updateKey(name, event.target.value);
      setSelectedKey(false);
    }
  }

  return (
    <Container sx={{ marginLeft: { lg: '7.6%', md: '7%', xs: 'auto' } }}>
      <SnackbarAlert msg={warningMsg} setMsg={setWarningMsg} severity="error" />
      <ModalWrapper
        handleClose={handleClose}
        isOpen={isOpen}
        dialogProps={{
          PaperProps: {
            sx: {
              '@media (max-height:800px)': {
                top: '95px',
              },
            },
          },
        }}
      >
        <DialogContent>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              marginBlock: '14px 1em',
              padding: '0',
              textAlign: 'center',
              lineHeight: {
                xl: '52px',
                lg: '48px',
                md: '44px',
                sm: '38px',
                xs: '32px',
              },
              fontSize: '32px',
            }}
          >
            Add Public key
          </DialogTitle>
          <TextField
            type="text"
            margin="normal"
            required
            fullWidth
            id="key"
            label="Key name"
            autoComplete="off"
            helperText={helperTextName}
            error={!!helperTextName}
            value={newNameKey}
            onChange={(e) => {
              setNewNameKey(e.target.value.replace(/[^a-zA-Z0-9]/g, ''));
              setHelperTextName('');
            }}
          />
          <TextField
            type="text"
            margin="normal"
            required
            fullWidth
            id="key"
            label="Public key"
            autoComplete="off"
            helperText={helperTextKey}
            error={!!helperTextKey}
            value={newKey}
            onChange={(e) => {
              setNewKey(e.target.value);
              setHelperTextKey('');
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={addKey}
              variant="text"
              sx={{
                padding: '16px 32px',
                boxShadow: '0px 0px 8px rgb(0 33 71 / 10%)',
                color: 'text.primary',
                backgroundColor:
                  theme.palette.mode === 'dark' ? '#151C2B' : '#FEFEFF',
                border: 'inherit',
                fontSize: '15px',
                margin: '16px 4px 16px 8px',
              }}
            >
              <img
                style={{ marginRight: '18px' }}
                src={'/icons/addsquare-' + theme.palette.mode + '.png'}
                alt="add"
              />
              Add Key
            </Button>
          </Box>
        </DialogContent>
      </ModalWrapper>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          component="h1"
          variant="h4"
          align="left"
          fontSize={48}
          lineHeight={1}
          sx={{ marginBottom: '8px' }}
        >
          Keys
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={handleOpen}
          variant="text"
          sx={{
            padding: '16px 32px',
            boxShadow: '0px 0px 8px rgb(0 33 71 / 10%)',
            color: 'text.primary',
            backgroundColor:
              theme.palette.mode === 'dark' ? '#151C2B' : '#FEFEFF',
            border: 'inherit',
            fontSize: '15px',
            margin: '16px 4px 16px 8px',
          }}
        >
          <img
            style={{ marginRight: '18px' }}
            src={'/icons/addsquare-' + theme.palette.mode + '.png'}
            alt="add"
          />
          Add Key
        </Button>
      </Box>
      <Table aria-label="Keys">
        <TableHead>
          <TableRow>
            <TableCell
              align="center"
              sx={{ width: '200px', padding: '21px 29px 24px' }}
            >
              Name
            </TableCell>
            <TableCell>Public Key</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {keys.map((k) => (
            <TableRow key={k.name} onClick={() => setSelectedKey(k.name)}>
              <TableCell
                align="center"
                sx={{
                  padding: 0,
                  height: '43px',
                  width: '200px',
                }}
              >
                {k.name}
              </TableCell>
              <TableCell sx={{ wordBreak: 'break-all' }}>
                {selectedKey === k.name ? (
                  <Box display="flex">
                    <TextField
                      multiline
                      rows={7}
                      type="text"
                      margin="normal"
                      fullWidth
                      variant="standard"
                      autoComplete="off"
                      InputProps={{
                        onKeyDown: (e) => handleKeyDown(e, k.name),
                      }}
                      defaultValue={k.key}
                      onBlur={(e) => handleBlur(e, k.name)}
                    />
                    <IconButton
                      color="inherit"
                      sx={{
                        margin: '8px',
                        width: '32px',
                        height: '32px',
                        color: 'primary.main',
                      }}
                      onClick={() => {
                        confirm({
                          confirmationText: 'Delete',
                          description:
                            'Are you sure you want to proceed with deleting the : ' +
                            k.name +
                            ' key?',
                        })
                          .then(() => {
                            deleteKey(k.name);
                          })
                          .catch(() => {});
                      }}
                    >
                      <TrashIcon />
                    </IconButton>
                  </Box>
                ) : (
                  k.key
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

export default Keys;

