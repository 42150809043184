export const drawerWidth = 340;

export const selfmonitoredInititalValue = {
  // mimir: "",
  // loki: "",
  email: '',
  password: '',
  protocol: '',
};

export const authInitialValues = {
  email: '',
  password: '',
};

export const resetPasswordInitialValues = {
  email: '',
};

export const resetPasswordFormInitialValues = {
  newPassword: '',
  confirmPassword: '',
};

export const serversDialogData = (ownServerClick: () => void) => [
  {
    id: 1,
    text: 'Bring your own server',
    onClick: ownServerClick,
  },
  {
    id: 2,
    text: 'kuutamo infrastructure platform',
    to: '/',
  },
];

export const addServerModalInitialValues = {
  server: {
    id: '',
    Provider: '',
    Type: '',
    IPv4: '',
    CIDR: '',
    Gateway: '',
    Username: '',
    disks: '',
    key: '',
  },
};

export const getTypeItems = (provider: string) => {
  switch (provider) {
    case 'OVH':
      return [{ id: 1, default: true, value: 'Rise1-g1-32-4' }];
    case 'kuutamo':
      return [{ id: 1, default: true, value: 'lightning' }];
    case 'Other':
      return [{ id: 1, default: true, value: '-' }];
    default:
      return [{ id: 1, default: true, value: 'lightning' }];
  }
};

export const alphaInitialValues = {
  token: '',
};

export const passwordModalFormInitialValues = {
  password: '',
};

