import React from 'react';

const NotFoundFour = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 140 180"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.9494 180C91.7299 180 88.1857 178.543 85.3165 175.629C82.4473 172.543 81.0126 168.771 81.0126 164.314V43.4571L86.3291 44.7429L37.4684 106.2L36.2025 102.857H125.823C129.873 102.857 133.249 104.229 135.949 106.971C138.65 109.714 140 113.143 140 117.257C140 121.2 138.65 124.543 135.949 127.286C133.249 129.857 129.873 131.143 125.823 131.143H15.1899C11.6456 131.143 8.18565 129.686 4.81013 126.771C1.60337 123.857 0 120.257 0 115.971C0 112.2 1.26582 108.771 3.79747 105.686L83.7975 6.17143C85.1477 4.28572 86.9198 2.82858 89.1139 1.80001C91.308 0.600002 93.7553 0 96.4557 0C100.844 0 104.388 1.54286 107.089 4.62857C109.958 7.54286 111.392 11.2286 111.392 15.6857V164.314C111.392 168.771 109.873 172.543 106.835 175.629C103.966 178.543 100.338 180 95.9494 180Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default NotFoundFour;
