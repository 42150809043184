import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { ConfirmProvider } from 'material-ui-confirm';
import theme from './ui/styles/mui-theme';
import Layout from './ui/components/layout';
import RoutersPage from './pages/routers';
import { CssBaseline } from '@mui/material';
import ServersPage from './pages/servers';
import KeysPage from './pages/keys';
import LayoutWithoutSidebar from './ui/components/layout/withoutSidebar';
// import AlphaPage from "./pages/alpha";
import RunModalPage from './pages/runModalPage';
import DownloadKeyDashboard from './pages/downloadModalPage';
import SigninPage from './pages/signin';
import SignupPage from './pages/signup';
import ConfirmEmailPage from './pages/confirmEmailPage';
import ResetPasswordPage from './pages/resetPassPage';
import Dashboard from './pages/dashboard';
import NotFoundPage from './pages/notFoundPage';
import DebugPage from './pages/debugPage';

function App() {
  const [mode, setMode] = useState<'dark' | 'light'>(
    (localStorage.getItem('siteMode') as 'dark' | 'light') || 'dark'
  );

  const changeTheme = () => {
    setMode((mode) => {
      const val = mode === 'dark' ? 'light' : 'dark';
      localStorage.setItem('siteMode', val);
      return val;
    });
  };
  return (
    <ThemeProvider theme={theme(mode)}>
      <CssBaseline />
      <BrowserRouter>
        <ConfirmProvider>
          <Routes>
            <Route path="/" element={<Layout changeTheme={changeTheme} />}>
              <Route path="/debug" element={<DebugPage />} />
              <Route path="/routers" element={<RoutersPage />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/servers" element={<ServersPage />} />
              <Route path="/keys" element={<KeysPage />} />
            </Route>
            <Route
              path="/"
              element={<LayoutWithoutSidebar changeTheme={changeTheme} />}
            >
              <Route path="/signin" element={<SigninPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route
                path="/forgot-password/:email/:token"
                element={<ResetPasswordPage />}
              />
              <Route
                path="/confirm/:email/:token"
                element={<ConfirmEmailPage />}
              />
              <Route path="/data-modal" element={<DownloadKeyDashboard />} />
              <Route path="/run-modal" element={<RunModalPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </ConfirmProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

