import { IMonitoringValues } from '../types/routes.types';
import { Buffer } from 'buffer';

class AuthService {
  #backendUrl;
  constructor() {
    this.#backendUrl =
      process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/';
  }

  async call<T = { status: 'fail' | 'success' }>(
    url: string,
    requestOptions: any
  ): Promise<T> {
    return fetch(url, requestOptions).then((response) => {
      return response.json() as Promise<T>;
    });
  }

  async signup(
    email: string,
    password: string
  ): Promise<{ status: 'success' } | { status: 'fail'; message: string }> {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          password,
        }),
      };
      return await this.call(`${this.#backendUrl}register`, requestOptions);
    } catch (e) {
      return { status: 'fail', message: JSON.stringify(e) };
    }
  }

  async login(
    values: IMonitoringValues
  ): Promise<
    | { status: 'success'; data: { token: string } }
    | { status: 'fail'; message: string }
  > {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...values,
        }),
      };

      return await this.call<
        | { status: 'success'; data: { token: string } }
        | { status: 'fail'; message: string }
      >(`${this.#backendUrl}login`, requestOptions);
    } catch (e) {
      return { status: 'fail', message: `{e.message}` };
    }
  }
  async checkToken(token: string) {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      return await this.call(
        `${this.#backendUrl}auth/check-token`,
        requestOptions
      );
      // if (data.status !== 200) {
      //   throw new Error(data.status);
      // }
      // return await data.json();
    } catch (e) {
      return { status: 'fail', message: 'Unauthorized' };
    }
  }
  async refreshToken(token: string): Promise<
    | {
        status: 'success' | 'fail';
        data: { token: string };
      }
    | any
  > {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      return await this.call(
        `${this.#backendUrl}auth/refresh-token`,
        requestOptions
      );
    } catch (e) {
      return { status: 'fail', message: 'Unauthorized' };
    }
  }

  async resendConfirmMail(email: string) {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      };

      return await this.call(
        `${this.#backendUrl}auth/resend-confirmation-code`,
        requestOptions
      );
      // if (data.status !== 200) throw new Error(data.status);
      // return await data.json();
    } catch (message) {
      return { status: 'fail', message };
    }
  }

  async confirmEmail(
    email: string,
    token: string
  ): Promise<
    { status: 'success'; data: string } | { status: 'fail'; message: string }
  > {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      return await this.call(
        `${this.#backendUrl}auth/confirmation/${email}/${token}`,
        requestOptions
      );
    } catch (message) {
      return { status: 'fail', message: `${message}` };
    }
  }

  async resetPassword(
    email: string
  ): Promise<{ status: 'fail' | 'success'; message?: string } | void> {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      };

      return await this.call<{ status: 'fail' | 'success' }>(
        `${this.#backendUrl}auth/forgot-password`,
        requestOptions
      );
    } catch (message) {
      return { status: 'fail', message: JSON.stringify(message) };
    }
  }

  async setNewPassword(
    email: string,
    token: string,
    newPassword: string
  ): Promise<{ status: 'success' } | { status: 'fail'; message: string }> {
    try {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPassword }),
      };
      return await this.call<
        { status: 'success' } | { status: 'fail'; message: string }
      >(
        `${this.#backendUrl}auth/change-password/${email}/${token}`,
        requestOptions
      );
    } catch (message) {
      return { status: 'fail', message: `${message}` };
    }
  }

  getRemainingTime(token: string) {
    const tokenParts = token.split('.');
    const decodedToken = JSON.parse(
      Buffer.from(tokenParts[1], 'base64').toString('utf-8')
    );
    const expirationTime = decodedToken.exp;
    const currentTime = Math.floor(Date.now() / 1000);
    return expirationTime - currentTime;
  }
}

export const authService = new AuthService();

