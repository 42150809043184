import { Box } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useWindowDimension } from '../../../hooks/useWindowDimension';
import NavBar from '../navbar';

interface ILayoutProps {
  changeTheme: () => void;
}

const LayoutWithoutSidebar = ({ changeTheme }: ILayoutProps) => {
  const { height } = useWindowDimension();

  return (
    <Box
      sx={{
        '@media (min-width: 2000px)': {
          width: '2000px',
          margin: 'auto',
          position: 'relative',
        },
      }}
    >
      <NavBar changeTheme={changeTheme} />
      <Box
        sx={{
          width: 1,
          minHeight: `calc(${height}px - 136px)`,
          display: 'flex',
          alignItems: 'stretch',
        }}
      >
        <Box
          component="main"
          sx={{
            display: 'flex',
            alignItems: 'stretch',
            width: 1,
            paddingBlock: '53px 24px',
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          height: '40px',
          fontFamily: "'Roboto', sans-serif",
          lineHeight: 1,
        }}
      >
        © 2023 kuutamo. All rights reserved
      </Box>
    </Box>
  );
};

export default LayoutWithoutSidebar;

