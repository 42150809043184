import React from 'react';
import ModalWrapper from '../modals';
import { Formik, FormikHelpers } from 'formik';
import { IAddNewRouterValues } from '../../../types/routes.types';
import { Box } from '@mui/material';
import AddRouterFormContent from './addRouterFormContent';

interface IAddRouterProps {
  handleClose: () => void;
  isOpen: boolean;
}

const AddRouter = ({ handleClose, isOpen }: IAddRouterProps) => {
  const initialValues = {
    aliasNode: '',
    kldNode: '',
    bitcoindDisks: '',
    db00: '',
    db01: '',
  };

  const handleSubmit = (
    values: IAddNewRouterValues,
    { resetForm }: FormikHelpers<IAddNewRouterValues>
  ) => {
    const routers = JSON.parse(localStorage.getItem('routers') || '[]');
    routers.push(values);
    localStorage.setItem('routers', JSON.stringify(routers));
    resetForm();
    handleClose();
  };

  return (
    <ModalWrapper
      title="Follow the steps"
      isOpen={isOpen}
      handleClose={handleClose}
      dialogProps={{
        sx: {
          '& .MuiPaper-root': {
            width: '576px',
            height: '743px',
          },
        },
      }}
    >
      <Box sx={{ padding: '0 93px 72px 93px', height: 'calc(100% - 110px)' }}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <AddRouterFormContent />
        </Formik>
      </Box>
    </ModalWrapper>
  );
};

export default AddRouter;
