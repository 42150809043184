class MonitoringService {
  #backendUrl;
  constructor() {
    this.#backendUrl =
      process.env.REACT_APP_BACKEND_URL || 'http://localhost:8080/';
  }

  async call<T = { status: 'fail' | 'success' }>(
    url: string,
    requestOptions: any
  ): Promise<T> {
    return fetch(url, requestOptions).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<T>;
    });
  }

  async getGrafanaToken(token: string): Promise<{ token: string } | void> {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      return await this.call(
        `${this.#backendUrl}get-grafana-token`,
        requestOptions
      );
    } catch (e) {
      console.log(e);
    }
  }

  async isExistsGrafanaToken(token: string) {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const data = await this.call(
        `${this.#backendUrl}is-exists-grafana-token`,
        requestOptions
      );

      return data.status === 'success';
    } catch (e) {
      return e;
    }
  }

  async checkGrafanaUrl(token: string) {
    try {
      const data = await fetch(
        `${process.env.REACT_APP_GRAFANA_URL}?auth_token=${token}`,
        { redirect: 'manual' }
      );
      return data.status === 200;
    } catch (e) {
      console.log(e);
      return true;
    }
  }
}

export const monitoringService = new MonitoringService();

