import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useWindowDimension } from '../../../hooks/useWindowDimension';
import Sidebar from '../sidebar';
import NavBar from '../navbar';

interface ILayoutProps {
  changeTheme: () => void;
}

const Layout = ({ changeTheme }: ILayoutProps) => {
  const { height } = useWindowDimension();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((old) => !old);
  };

  return (
    <Box
      sx={{
        '@media (min-width: 2000px)': {
          width: '2000px',
          margin: 'auto',
          position: 'relative',
        },
      }}
    >
      <NavBar
        changeTheme={changeTheme}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        sx={{
          width: 1,
          minHeight: `calc(${height}px - 136px)`,
          display: 'flex',
          alignItems: 'stretch',
        }}
      >
        <Sidebar
          sidebarMobileOpen={mobileOpen}
          changeTheme={changeTheme}
          handleDrawerToggle={handleDrawerToggle}
        />

        <Box
          component="main"
          sx={{
            display: 'flex',
            alignItems: 'stretch',
            width: 1,
            paddingBlock: '53px 24px',
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          width: '100%',
          height: '40px',
          fontFamily: "'Roboto', sans-serif",
          lineHeight: 1,
        }}
      >
        © 2023 kuutamo. All rights reserved
      </Box>
    </Box>
  );
};

export default Layout;

