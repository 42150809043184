import { Field, Form, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import MuiTextField from '../MuiTextField';
import { MuiStyledSelect, StyledMenuItem } from '../select/select.components';
import { Box, Button, useTheme } from '@mui/material';
import { IServer } from '../../../types/servers.types';
import SelectArrow from '../../svg/selectArrow';
import { IAddNewRouterValues } from '../../../types/routes.types';

const AddRouterFormContent = () => {
  const [bitcoindIsShown, setBitcoindIsShown] = useState(false);
  const { values } = useFormikContext<IAddNewRouterValues>();
  const theme = useTheme();
  const servers: IServer[] = JSON.parse(
    localStorage.getItem('servers') || '[]'
  );

  useEffect(() => {
    const serv = servers.find((el) => el.id === values.kldNode);
    if (serv && serv.Provider === 'OVH' && serv.Type === 'Rise1-g1-32-4') {
      setBitcoindIsShown(true);
      values.bitcoindDisks = '/dev/sda, /dev/sdb';
    } else {
      setBitcoindIsShown(false);
      values.bitcoindDisks = '';
    }
  }, [values, servers]);

  const inputLabelProps = {
    sx: {
      fontFamily: "'Roboto', sans-serif",
      lineHeight: 1,
      top: '3px',
    },
    shrink: true,
  };

  const formHelperTextProps = {
    sx: {
      fontWeight: '400',
      fontFamily: "'Roboto', sans-serif",
      fontSize: '14px',
      position: 'absolute',
      bottom: '-25px',
    },
  };

  const styles = { width: '100%', marginTop: '40px' };

  const IconComponent = (props: any) => (
    <SelectArrow {...props} className={`${props.className} router`} />
  );

  const serversForKldNode = () =>
    servers.filter(
      (server) => server.id !== values.db00 && server.id !== values.db01
    );

  const serversForDB00 = () =>
    servers.filter(
      (server) => server.id !== values.kldNode && server.id !== values.db01
    );

  const serversForDB01 = () =>
    servers.filter(
      (server) => server.id !== values.db00 && server.id !== values.kldNode
    );

  const selectProps = {
    displayEmpty: true,

    IconComponent,
    sx: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: '18px',
    },
    MenuProps: {
      PaperProps: {
        sx: {
          padding: '0',
          borderRadius: '10px',
          boxShadow:
            theme.palette.mode === 'dark'
              ? '0px 0px 8px rgba(7, 9, 14, 0.1);'
              : '0px 0px 8px rgb(0 33 71 / 10%)',
          backgroundColor: 'primary.light',
          backgroundImage: 'none',
          border: 1,
          borderColor: 'primary.dark',
        },
      },
    },
  };

  return (
    <Form style={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box>
          <Field
            name="aliasNode"
            component={MuiTextField}
            label="node Alias:"
            required
            InputLabelProps={{
              sx: {
                fontFamily: "'Roboto', sans-serif",
                lineHeight: 1,

                top: '3px',
              },
            }}
            sx={{ width: '100%' }}
          />
          <Field
            name="kldNode"
            variant="outlined"
            label="kld-node:"
            required
            component={MuiStyledSelect}
            FormHelperTextProps={formHelperTextProps}
            select
            sx={styles}
            InputLabelProps={inputLabelProps}
            SelectProps={selectProps}
          >
            <StyledMenuItem disabled value={``}>
              Select
            </StyledMenuItem>
            {serversForKldNode().map((server) => (
              <StyledMenuItem key={server.id} value={server.id}>
                {server.id}
              </StyledMenuItem>
            ))}
          </Field>
          {bitcoindIsShown && (
            <Field
              name="bitcoindDisks"
              component={MuiTextField}
              label="bitcoind disks:"
              disabled={!!values.aliasNode}
              InputLabelProps={{
                sx: {
                  fontFamily: "'Roboto', sans-serif",
                  lineHeight: 1,

                  top: '3px',
                },
              }}
              sx={{
                width: 'calc(100% - 48px)',
                marginTop: '24px',
                marginLeft: '48px',
              }}
            />
          )}
          <Field
            name="db00"
            variant="outlined"
            component={MuiStyledSelect}
            select
            label="db-00:"
            InputLabelProps={inputLabelProps}
            FormHelperTextProps={formHelperTextProps}
            sx={styles}
            SelectProps={selectProps}
          >
            <StyledMenuItem disabled value={``}>
              Select
            </StyledMenuItem>
            {serversForDB00().map((server) => (
              <StyledMenuItem key={server.id} value={server.id}>
                {server.id}
              </StyledMenuItem>
            ))}
          </Field>
          <Field
            name="db01"
            component={MuiStyledSelect}
            label="db-01:"
            InputLabelProps={inputLabelProps}
            select
            FormHelperTextProps={formHelperTextProps}
            sx={{ ...styles }}
            SelectProps={selectProps}
          >
            <StyledMenuItem disabled value={``}>
              Select
            </StyledMenuItem>
            {serversForDB01().map((server) => (
              <StyledMenuItem key={server.id} value={server.id}>
                {server.id}
              </StyledMenuItem>
            ))}
          </Field>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              padding: '16px 32px',
              fontSize: '15px',
              lineHeight: 1,
              fontWeight: '400',

              '&:hover': {
                backgroundColor: 'primary.main',
              },
            }}
          >
            CONTINUE
          </Button>
        </Box>
      </Box>
    </Form>
  );
};

export default AddRouterFormContent;
