import React from 'react';

const OvhLogo = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 152 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_901_2113)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.3035 1.36838C39.2705 4.90444 40.1393 8.94382 39.7997 12.9735C39.4601 17.0031 37.9273 20.8411 35.396 24H24.4613L27.8274 18.0636H23.375L28.623 8.84103H33.101L37.3035 1.37855V1.36838ZM15.7094 24H4.56048C1.982 20.8549 0.417533 17.0054 0.0725573 12.9573C-0.272418 8.90914 0.618227 4.85157 2.62752 1.31751L9.85954 13.8465L17.8311 0H29.5615L15.7145 23.9898L15.7094 24Z"
          fill="currentColor"
        />
        <path
          d="M46.5176 12.8599C46.5176 6.45551 49.7154 3.37793 54.9685 3.37793C60.2217 3.37793 63.4144 6.45551 63.4144 12.8599C63.4144 19.2643 60.1962 22.3063 54.9685 22.3063C49.7409 22.3063 46.5176 19.2033 46.5176 12.8599ZM48.4148 12.8599C48.4148 18.1249 50.8221 20.6327 54.9685 20.6327C59.115 20.6327 61.5171 18.1249 61.5171 12.8599C61.5171 7.59497 59.1099 5.05152 54.9685 5.05152C50.8272 5.05152 48.4148 7.56445 48.4148 12.8599Z"
          fill="currentColor"
        />
        <path
          d="M77.2213 4.13522C77.2884 3.96013 77.4072 3.80949 77.562 3.70322C77.7168 3.59695 77.9004 3.54005 78.0883 3.54005C78.2095 3.53937 78.3296 3.56276 78.4416 3.60885C78.5536 3.65494 78.6553 3.72282 78.7408 3.80852C78.8262 3.89422 78.8937 3.99603 78.9393 4.10802C78.9849 4.22 79.0077 4.33992 79.0063 4.46078C79.0018 4.59834 78.9743 4.73418 78.9247 4.86265L72.5903 21.5222C72.5316 21.7049 72.4162 21.8642 72.2609 21.9773C72.1055 22.0903 71.9182 22.1513 71.7258 22.1513C71.5335 22.1513 71.3462 22.0903 71.1908 21.9773C71.0354 21.8642 70.9201 21.7049 70.8614 21.5222L64.527 4.86265C64.4774 4.73418 64.4499 4.59834 64.4454 4.46078C64.444 4.33992 64.4668 4.22 64.5124 4.10802C64.558 3.99603 64.6255 3.89422 64.7109 3.80852C64.7964 3.72282 64.8981 3.65494 65.0101 3.60885C65.1221 3.56276 65.2422 3.53937 65.3634 3.54005C65.5513 3.54005 65.7348 3.59695 65.8897 3.70322C66.0445 3.80949 66.1633 3.96013 66.2304 4.13522L71.7233 18.6634L77.2213 4.13522Z"
          fill="currentColor"
        />
        <path
          d="M82.8515 13.4502V21.228C82.861 21.3544 82.8442 21.4813 82.8022 21.6008C82.7602 21.7204 82.6939 21.83 82.6075 21.9228C82.5211 22.0157 82.4164 22.0897 82.3 22.1404C82.1836 22.191 82.0579 22.2172 81.9309 22.2172C81.8039 22.2172 81.6783 22.191 81.5619 22.1404C81.4455 22.0897 81.3408 22.0157 81.2544 21.9228C81.168 21.83 81.1017 21.7204 81.0597 21.6008C81.0177 21.4813 81.0009 21.3544 81.0104 21.228V4.44127C81.0009 4.31494 81.0177 4.18804 81.0597 4.06849C81.1017 3.94893 81.168 3.83931 81.2544 3.74647C81.3408 3.65363 81.4455 3.57957 81.5619 3.52893C81.6783 3.47829 81.8039 3.45215 81.9309 3.45215C82.0579 3.45215 82.1836 3.47829 82.3 3.52893C82.4164 3.57957 82.5211 3.65363 82.6075 3.74647C82.6939 3.83931 82.7602 3.94893 82.8022 4.06849C82.8442 4.18804 82.861 4.31494 82.8515 4.44127V11.7766H92.2766V4.46162C92.2672 4.33529 92.284 4.20838 92.326 4.08883C92.368 3.96928 92.4342 3.85966 92.5206 3.76682C92.607 3.67398 92.7117 3.59992 92.8281 3.54928C92.9445 3.49864 93.0702 3.4725 93.1972 3.4725C93.3242 3.4725 93.4498 3.49864 93.5662 3.54928C93.6827 3.59992 93.7873 3.67398 93.8737 3.76682C93.9602 3.85966 94.0264 3.96928 94.0684 4.08883C94.1104 4.20838 94.1272 4.33529 94.1178 4.46162V21.2484C94.1272 21.3747 94.1104 21.5016 94.0684 21.6212C94.0264 21.7407 93.9602 21.8503 93.8737 21.9432C93.7873 22.036 93.6827 22.1101 93.5662 22.1607C93.4498 22.2114 93.3242 22.2375 93.1972 22.2375C93.0702 22.2375 92.9445 22.2114 92.8281 22.1607C92.7117 22.1101 92.607 22.036 92.5206 21.9432C92.4342 21.8503 92.368 21.7407 92.326 21.6212C92.284 21.5016 92.2672 21.3747 92.2766 21.2484V13.4502H82.8515Z"
          fill="currentColor"
        />
        <path
          d="M102.808 9.29375C103.6 9.25983 104.389 9.41043 105.112 9.73361C105.835 10.0568 106.473 10.5436 106.975 11.1556C107.096 11.2977 107.163 11.4778 107.164 11.6642C107.166 11.7677 107.147 11.8705 107.108 11.9665C107.069 12.0625 107.012 12.1496 106.938 12.2228C106.865 12.296 106.777 12.3536 106.681 12.3922C106.585 12.4309 106.482 12.4497 106.378 12.4476C106.252 12.4484 106.128 12.4194 106.015 12.3627C105.902 12.3061 105.805 12.2236 105.73 12.1221C105.378 11.697 104.929 11.3622 104.421 11.1454C103.912 10.9286 103.36 10.8361 102.808 10.8758C100.314 10.8758 98.9881 12.468 98.9881 15.7897C98.9881 19.1115 100.314 20.7291 102.808 20.7291C103.957 20.7183 105.066 20.3082 105.945 19.5693C106.087 19.4487 106.268 19.3821 106.455 19.3811C106.663 19.3811 106.863 19.4636 107.01 19.6105C107.157 19.7574 107.24 19.9567 107.24 20.1645C107.239 20.2962 107.207 20.4259 107.146 20.5426C107.084 20.6594 106.996 20.7599 106.888 20.8359C105.751 21.8003 104.301 22.3192 102.808 22.2959C99.3145 22.2959 97.2285 20.2153 97.2285 15.7897C97.2285 11.3641 99.3145 9.29375 102.808 9.29375Z"
          fill="currentColor"
        />
        <path
          d="M111.443 3.56574V19.0147C111.443 20.0931 111.795 20.5407 112.524 20.5407C112.637 20.5319 112.749 20.5463 112.856 20.583C112.962 20.6198 113.06 20.6781 113.142 20.7543C113.225 20.8305 113.291 20.9229 113.336 21.0258C113.381 21.1287 113.405 21.2398 113.405 21.3521C113.405 21.4644 113.381 21.5755 113.336 21.6784C113.291 21.7812 113.225 21.8737 113.142 21.9499C113.06 22.0261 112.962 22.0844 112.856 22.1212C112.749 22.1579 112.637 22.1723 112.524 22.1635C110.714 22.1635 109.709 21.0799 109.709 19.0045V3.56574C109.715 3.33891 109.809 3.12322 109.97 2.96323C110.132 2.80325 110.349 2.71118 110.576 2.70605C110.805 2.70605 111.025 2.79646 111.187 2.95754C111.35 3.11862 111.442 3.33727 111.443 3.56574Z"
          fill="currentColor"
        />
        <path
          d="M126.198 15.7747C126.198 20.2003 124.031 22.2808 120.512 22.2808C116.993 22.2808 114.83 20.2003 114.83 15.7747C114.83 11.3491 117.023 9.29395 120.512 9.29395C124 9.29395 126.198 11.344 126.198 15.7747ZM124.439 15.7747C124.439 12.3715 123.006 10.8607 120.512 10.8607C118.018 10.8607 116.59 12.3868 116.59 15.7747C116.59 19.1625 118.023 20.714 120.512 20.714C123.001 20.714 124.459 19.2287 124.459 15.7747H124.439Z"
          fill="currentColor"
        />
        <path
          d="M130.371 10.321V17.3663C130.371 19.1213 130.937 20.6321 133.671 20.6321C136.404 20.6321 136.976 19.106 136.976 17.3663V10.321C136.977 10.2056 137.001 10.0917 137.047 9.98571C137.092 9.87973 137.159 9.7838 137.242 9.70343C137.324 9.62306 137.423 9.55984 137.53 9.51741C137.638 9.47498 137.753 9.45417 137.868 9.45619C137.98 9.45752 138.091 9.48098 138.195 9.52522C138.298 9.56946 138.391 9.63361 138.469 9.71398C138.547 9.79436 138.609 9.88936 138.65 9.99353C138.692 10.0977 138.712 10.209 138.71 10.321V17.3663C138.71 19.9098 137.868 22.2803 133.671 22.2803C129.473 22.2803 128.637 19.9301 128.637 17.3663V10.321C128.635 10.2068 128.656 10.0935 128.699 9.9876C128.741 9.88175 128.805 9.78559 128.886 9.70487C128.967 9.62415 129.064 9.56053 129.17 9.51781C129.276 9.47508 129.389 9.45412 129.504 9.45619C129.733 9.45753 129.953 9.54907 130.115 9.71096C130.278 9.87284 130.37 10.092 130.371 10.321Z"
          fill="currentColor"
        />
        <path
          d="M150.267 11.0488V3.56595C150.267 3.33659 150.358 3.11663 150.521 2.95446C150.683 2.79228 150.904 2.70117 151.134 2.70117C151.364 2.70117 151.584 2.79228 151.747 2.95446C151.909 3.11663 152.001 3.33659 152.001 3.56595V16.9801C152.001 20.5409 149.782 22.301 146.697 22.301C143.203 22.301 141.143 20.2205 141.143 15.7949C141.143 11.3693 143.096 9.31414 146.584 9.31414C147.291 9.3012 147.99 9.451 148.629 9.75186C149.268 10.0527 149.828 10.4966 150.267 11.0488ZM150.267 13.2361C150.267 13.2361 149.247 10.8606 146.697 10.8606C144.147 10.8606 142.877 12.4528 142.877 15.7745C142.877 19.0963 144.203 20.7139 146.697 20.7139C148.778 20.7139 150.267 19.5286 150.267 16.9598V13.2361Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_901_2113">
          <rect width="152" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OvhLogo;

