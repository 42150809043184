import { TextField } from '@mui/material';
import React from 'react';
import { FieldProps } from 'formik';
import { FormikProps } from 'formik';

interface IMuiTextField {
  field: FieldProps;
  form: FormikProps<any>;
}

const MuiTextField = ({ field, form, ...props }: IMuiTextField) => {
  return (
    <TextField
      inputProps={{ sx: { fontFamily: "'Roboto', sans-serif" } }}
      InputLabelProps={{ sx: { fontFamily: "'Roboto', sans-serif" } }}
      {...field}
      {...props}
    />
  );
};

export default MuiTextField;

