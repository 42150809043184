import React from 'react';
import LinkButton from './LinkButton';
import ModalWrapper from './modals';
import { Box, Button } from '@mui/material';

interface ILinkButtonProps {
  id: number;
  to?: string;
  onClick?: () => void;
  title?: string;
  text: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  containerProps?: any;
  textProps?: any;
}

interface IChooseDialogProps {
  data: Array<ILinkButtonProps>;
  isOpen: boolean;
  handleClose?: () => void;
  title?: string;
  withBackButton?: boolean;
  buttonText?: string;
  buttonProps?: any;
  dialogProps?: any;
  buttonComponent?: any;
  buttonLink?: string;
  submitButtonText?: string;
}

const ChooseDialog = ({
  buttonComponent,
  buttonLink,
  submitButtonText,
  data,
  ...props
}: IChooseDialogProps) => {
  return (
    <ModalWrapper {...props}>
      {data.map((dataEl) => (
        <LinkButton key={dataEl.id} {...dataEl} />
      ))}
      {submitButtonText && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '16px',
            marginBottom: '72px',
            textDecoration: 'none',
          }}
          component={buttonComponent}
          to={buttonLink}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{
              padding: '16px 32px',
              fontSize: '15px',
              lineHeight: 1,
              fontWeight: '400',

              '&:hover': {
                backgroundColor: 'primary.main',
              },
            }}
          >
            CONTINUE
          </Button>
        </Box>
      )}
    </ModalWrapper>
  );
};

export default ChooseDialog;

