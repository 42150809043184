import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import Logo from '../../svg/logo';
import GitHubIcon from '../../svg/github';
import { getCustomThemeStyles } from '../../styles/theme';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { StyledButton } from './navbar.components';
import LogoutIcon from '../../svg/logout';
import { authService } from '../../../helpers/auth';

interface INavBarProps {
  changeTheme: () => void;
  handleDrawerToggle?: () => void;
}

const NavBar = ({ changeTheme, handleDrawerToggle }: INavBarProps) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const customTheme = getCustomThemeStyles(theme.palette.mode === 'dark');
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('TOKEN');
    if (token) {
      const timeRemaining = authService.getRemainingTime(token);
      const timeInMinutes = timeRemaining / 60;

      setIsAuthorized(timeInMinutes > 0);
    }
  }, []);

  const firstLogoColor = theme.palette.mode === 'dark' ? '#FEFEFF' : '#4D4DFF';

  const secondLogoColor = theme.palette.mode === 'dark' ? '#FEFEFF' : '#9900FF';

  const signOut = () => {
    localStorage.removeItem('TOKEN');
    navigate(0);
  };

  const onSignIn = location.pathname === '/signin';

  return (
    <>
      <AppBar
        position="relative"
        sx={{
          zIndex: 1301,
          height: '96px',
          justifyContent: 'center',
          color: 'inherit',
          bgcolor: theme.palette.mode === 'dark' ? '#091429' : '#FEFEFF',
          borderBottom: '1px solid ',
          borderColor: 'info.main',
          backgroundImage: 'none',
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          {handleDrawerToggle && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            <Box display="flex" alignItems="center">
              <Box
                component={Link}
                to="https://github.com/kuutamolabs/lightning-ui"
                target="_blank"
              >
                <IconButton
                  sx={{
                    color: customTheme.colors.github,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <GitHubIcon />
                </IconButton>
              </Box>
              <IconButton onClick={changeTheme} color="inherit">
                {theme.palette.mode === 'dark' ? (
                  <img src="/icons/ic-sun.png" alt="dark mode" />
                ) : (
                  <img src="/icons/ic-circular.png" alt="light mode" />
                )}
              </IconButton>
              <IconButton color="inherit">
                <img
                  src={'/icons/ic-notifications-' + theme.palette.mode + '.png'}
                  alt="notifications"
                />
              </IconButton>
            </Box>
          </Typography>
          <Typography component="div" sx={{ flexGrow: 1 }}>
            <Box
              display="flex"
              alignItems="center"
              sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}
              pl={2}
            >
              <Box width={49} height={49}>
                <Logo
                  firstColor={firstLogoColor}
                  secondColor={secondLogoColor}
                />
              </Box>
              <Typography
                pl={1}
                pr={2}
                sx={{
                  color: 'text.primary',

                  fontWeight: 600,
                  fontSize: '32px',
                }}
              >
                kuutamo
              </Typography>
              <Typography
                pl={2}
                sx={{
                  color: 'text.primary',
                  fontWeight: 400,
                  fontSize: '16px',
                  borderLeft: '1px solid #D2D1DA',
                  display: { xs: 'none', sm: 'block' },
                }}
              >
                protocol infrastucture
              </Typography>
            </Box>
          </Typography>
          {isAuthorized ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '88px',
                textDecoration: 'none',
              }}
            >
              <StyledButton onClick={() => signOut()}>
                <LogoutIcon
                  arrowColor={theme.palette.primary.main}
                  doorColor={theme.palette.text.primary}
                />

                <Box sx={{ marginLeft: '8px' }}>Log out</Box>
              </StyledButton>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '88px',
                textDecoration: 'none',
              }}
              component={Link}
              to={onSignIn ? '/signup' : '/signin'}
            >
              <StyledButton>
                <LogoutIcon
                  arrowColor={theme.palette.primary.main}
                  doorColor={theme.palette.text.primary}
                />

                <Box sx={{ marginLeft: '8px' }}>
                  {onSignIn ? 'Sign up' : 'Log In'}
                </Box>
              </StyledButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NavBar;
