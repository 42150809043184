import TreeView from '@mui/lab/TreeView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import * as zip from '@zip.js/zip.js';
import { Box, Chip, Drawer, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import React, { useState } from 'react';
import { StyledTreeHeader, StyledTreeItem } from './sidebar.components';
import { drawerWidth } from '../../../constants';
import PassModalForm from '../modals/passForm';
import SnackbarAlert from '../SnackbarAlert';

interface ISidebarProps {
  sidebarMobileOpen: boolean;
  handleDrawerToggle: () => void;
  changeTheme: () => void;
}

const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

// const buttonStyles = { lineHeight: 1, width: 264, height: 1, fontSize: '16px' };

const Sidebar = ({
  sidebarMobileOpen,
  handleDrawerToggle,
  changeTheme,
}: ISidebarProps) => {
  const theme = useTheme();
  // const inputRef = useRef<HTMLInputElement>(null);
  const [exportPassModalIsOpen, setExportPassModalIsOpen] =
    useState<boolean>(false);
  const [importPassModalIsOpen, setImportPassModalIsOpen] =
    useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const [successText, setSuccessText] = useState<string>('');
  const [file, setFile] = useState<File | undefined>();

  // const handleImportClick = () => {
  //   inputRef.current && inputRef.current.click();
  // };

  // const fileSelect = async (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e && e.target && e.target.files && e.target.files[0]) {
  //     const selectedFile = e?.target?.files[0];
  //     if (selectedFile) setFile(e.target.files[0]);

  //     setImportPassModalIsOpen(true);
  //   }
  //   // if (selectedFile.type.split('/')[0] !== 'image') {
  //   //   return setFileDropError('Please provide an image file to upload!');
  //   // }

  //   // setFileDropError('');
  // };

  // const handleExportData = () => {
  //   setExportPassModalIsOpen(true);
  // };

  const downloadFile = (blob: Blob) => {
    if (!blob) return false;
    const a = document.createElement('a');
    a.download = `lightning.kuutamo.app.zip`;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  const getExportBlob = async (password: string) => {
    const routers = JSON.parse(localStorage.getItem('routers') || '[]');
    const servers = JSON.parse(localStorage.getItem('servers') || '[]');
    const keys = JSON.parse(localStorage.getItem('keys') || '[]');
    const siteMode = localStorage.getItem('siteMode') || 'dark';
    const iframeAutoRefresh = localStorage.getItem('iframeAutoRefresh') || '5s';

    const data = {
      routers,
      servers,
      keys,
      siteMode,
      iframeAutoRefresh,
    };
    const zipWriter = new zip.ZipWriter(new zip.BlobWriter('application/zip'));

    await zipWriter.add(
      'lightning.kuutamo.app.json',
      new zip.TextReader(JSON.stringify(data, null, 2)),
      { password }
    );

    return await zipWriter.close();
  };

  const handleFileSelected = async (password: string) => {
    if (file && password) {
      let reader;
      try {
        reader = new zip.ZipReader(new zip.BlobReader(file), {
          password,
        });
        const entries = await reader.getEntries();
        if (entries && entries.length && entries[0].getData) {
          const text = await entries[0].getData(new zip.TextWriter());
          if (isJsonString(text)) {
            const json = JSON.parse(text);
            Object.entries(json).forEach((objItem) => {
              const value =
                typeof objItem[1] === 'object'
                  ? JSON.stringify(objItem[1])
                  : objItem[1];

              typeof value === 'string' &&
                localStorage.setItem(objItem[0], value);
            });
            setSuccessText('Imported successfully');
          } else {
            setSuccessText('');
            setErrorText('Error occurred while importing file');
          }
        }
      } catch (e) {
        setSuccessText('');
        setErrorText('Error occurred while importing file');
      } finally {
        reader && (await reader.close());
      }
    }
  };
  const handleExportPassModalClose = () => {
    setExportPassModalIsOpen(false);
  };

  const handleImportPassModalClose = () => {
    setImportPassModalIsOpen(false);
  };

  const handleExportPassSubmit = (password: string) => {
    getExportBlob(password).then(downloadFile);
  };

  const handleImportPassSubmit = async (password: string) => {
    setImportPassModalIsOpen(false);
    handleFileSelected(password);
    setFile(undefined);
  };

  const drawer = (
    <>
      <PassModalForm
        isOpen={exportPassModalIsOpen}
        handleClose={handleExportPassModalClose}
        onSubmit={handleExportPassSubmit}
        label="Password for encrypting"
      />
      <PassModalForm
        isOpen={importPassModalIsOpen}
        handleClose={handleImportPassModalClose}
        onSubmit={handleImportPassSubmit}
        label="Password for decrypting"
        message="PLEASE READ THIS TEXT! All existing data will be replaced!"
      />
      <SnackbarAlert
        msg={successText}
        setMsg={setSuccessText}
        severity="success"
      />
      <SnackbarAlert msg={errorText} setMsg={setErrorText} severity="error" />
      <TreeView
        sx={{ paddingTop: { xs: '126px', sm: '146px' }, paddingLeft: '20px' }}
        aria-label="protocol"
        defaultCollapseIcon={<ArrowDropDownIcon sx={{ marginLeft: '450px' }} />}
        defaultExpandIcon={<ArrowLeftIcon sx={{ marginLeft: '450px' }} />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        <Box display={{ xs: 'flex', sm: 'none' }} sx={{ marginBottom: '10px' }}>
          <IconButton sx={{ ml: 1 }} onClick={changeTheme} color="inherit">
            {theme.palette.mode === 'dark' ? (
              <img src="/icons/ic-sun.png" alt="dark mode" />
            ) : (
              <img src="/icons/ic-circular.png" alt="light mode" />
            )}
          </IconButton>
          <IconButton sx={{ ml: 1 }} color="inherit">
            <img
              src={'/icons/ic-notifications-' + theme.palette.mode + '.png'}
              alt="notifications"
            />
          </IconButton>
        </Box>
        <Box pl={1} pr={1}>
          <Chip
            sx={{
              width: 264,
              height: 32,
              fontSize: '16px',
            }}
            label="PROTOCOL"
          />
        </Box>
        <StyledTreeHeader nodeId="1" labelText="Routers">
          <StyledTreeItem nodeId="11" labelText="List" to="/routers" />
          <StyledTreeItem nodeId="12" labelText="Monitoring" to="/dashboard" />
        </StyledTreeHeader>
        <Box pl={1} pr={1} pt={5}>
          <Chip
            sx={{
              width: 264,
              height: 32,
              fontSize: '16px',
            }}
            label="INFRASTRUCTURE"
          />
        </Box>
        <StyledTreeItem nodeId="3" labelText="Servers" to="/servers" />
        <StyledTreeItem nodeId="4" labelText="Keys" to="/keys" />
      </TreeView>
    </>
  );

  return (
    <Box>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={sidebarMobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },

            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },

            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              borderWidth: 0,
              background: 'none',
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Sidebar;

