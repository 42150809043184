import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '20px',
  padding: '5px',
  fontSize: '20px',
  color: theme.palette.text.primary,
  backgroundColor: 'none',
  textTransform: 'none',
}));

