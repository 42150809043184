import React from 'react';

const GitHubIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9404 0.408203C8.91388 0.408203 0 9.38779 0 20.4968C0 29.3768 5.71143 36.8935 13.6347 39.5539C14.6253 39.7539 14.9882 39.1217 14.9882 38.5898C14.9882 38.1241 14.9555 36.5278 14.9555 34.8645C9.40857 36.0621 8.25347 32.4698 8.25347 32.4698C7.36204 30.1417 6.04122 29.5433 6.04122 29.5433C4.22571 28.3127 6.17347 28.3127 6.17347 28.3127C8.18735 28.4458 9.24408 30.3747 9.24408 30.3747C11.0265 33.4343 13.8988 32.5698 15.0543 32.0376C15.2192 30.7404 15.7478 29.8425 16.309 29.3437C11.8849 28.878 7.2302 27.1486 7.2302 19.4323C7.2302 17.2372 8.02204 15.4413 9.27673 14.0445C9.07878 13.5458 8.38531 11.4833 9.4751 8.7229C9.4751 8.7229 11.1588 8.19065 14.9551 10.7849C16.5804 10.3452 18.2566 10.1215 19.9404 10.1196C21.6241 10.1196 23.3404 10.3527 24.9253 10.7849C28.722 8.19065 30.4057 8.7229 30.4057 8.7229C31.4955 11.4833 30.8016 13.5458 30.6037 14.0445C31.8914 15.4413 32.6506 17.2372 32.6506 19.4323C32.6506 27.1486 27.9959 28.8445 23.5388 29.3437C24.2653 29.9755 24.8922 31.1727 24.8922 33.0686C24.8922 35.7625 24.8596 37.9245 24.8596 38.5894C24.8596 39.1217 25.2229 39.7539 26.2131 39.5543C34.1363 36.8931 39.8478 29.3768 39.8478 20.4968C39.8804 9.38779 30.9339 0.408203 19.9404 0.408203Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GitHubIcon;

