import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { StyledSelect } from './select/select.components';
import { IModalServer } from '../../types/servers.types';

const DependentDiskField = (props: any) => {
  const {
    values: { server },
    setFieldValue,
  } = useFormikContext<IModalServer>();
  const [field] = useField(props);

  useEffect(() => {
    if (server && server.Type && server.Type === 'Rise1-g1-32-4') {
      setFieldValue(props.name, '4');
    }
  }, [server, setFieldValue, props.name]);

  return <StyledSelect {...props} {...field} />;
};

export default DependentDiskField;

