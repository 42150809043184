import {
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  Typography,
  IconButton,
  FormControl,
  DialogTitle,
  Divider,
  InputAdornment,
  Alert,
} from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import ChooseDialog from '../ui/components/ChooseDialog';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { styled } from '@mui/material/styles';

import { selfmonitoredInititalValue } from '../constants';
import { authService } from '../helpers/auth';
import FileLinkIcon from '../ui/svg/link';
import { getCustomThemeStyles } from '../ui/styles/theme';
import { monitoredValidator } from '../validators/monitoredValidators';
import { IKey, IMonitoringValues, IRouter } from '../types/routes.types';
import MuiTextField from '../ui/components/MuiTextField';
import LinkButton from '../ui/components/LinkButton';
import EyeIcon from '../ui/svg/eye';
import EyeSlashIcon from '../ui/svg/eyeSlash';
import SnackbarAlert from '../ui/components/SnackbarAlert';
import SignInForgotPassModal from '../ui/components/modals/signInForgotPasModal';
import SignUpConfirmDialog from '../ui/components/modals/confirmDialog';
import ModalWrapper from '../ui/components/modals';
import AddRouter from '../ui/components/modals/addRouter';
import { IServer } from '../types/servers.types';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontFamily: "'Roboto', sans-serif",
  fontWeight: 700,
  fontSize: '16px',
}));

const RoutersPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isResent, setIsResent] = useState(false);
  const [secs, setSecs] = useState(60);
  const [confirmModalIsShown, setConfirmModalIsShown] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [resetModalIsShown, setResetModalIsShown] = useState(false);
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [isNewRouterOpen, setIsNewRouterOpen] = useState(false);
  // const [myRouters, setMyRouters] = useState({});
  // const [myRoutersIsReady, setRoutersIsReady] = useState(false);
  const [openMountDialog, setOpenMountDialog] = useState(false);
  const [warningMsg, setWarningMsg] = useState('');
  const servers = JSON.parse(
    localStorage.getItem('servers') || '[]'
  ) as Array<IServer>;

  const keys = JSON.parse(localStorage.getItem('keys') || '[]') as Array<IKey>;
  const routers = JSON.parse(
    localStorage.getItem('routers') || '[]'
  ) as Array<IRouter>;

  const [selectedRouter, setSelectedRouter] = useState('');

  const [index, setIndex] = useState<number>();

  const customTheme = getCustomThemeStyles(theme.palette.mode === 'dark');

  useEffect(() => {
    const token = localStorage.getItem('TOKEN');
    if (token) {
      const timeRemaining = authService.getRemainingTime(token);
      const timeInMinutes = timeRemaining / 60;
      if (timeInMinutes > 0) {
        setIsAuthorized(true);
      }
    }
  }, [navigate]);

  function dataURItoBlob(dataURI: string) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const _ia = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }
    const dataView = new DataView(arrayBuffer);
    const blob = new Blob([dataView], { type: mimeString });
    return blob;
  }

  const downloadKeyFile = () => {
    const key = localStorage.getItem('key_' + selectedRouter);
    if (!key) {
      setWarningMsg('Key file not found');
      return false;
    }
    const blob = dataURItoBlob(key);
    const a = document.createElement('a');
    a.download = selectedRouter + '.zip';
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  const downloadConfigFile = () => {
    if (typeof index === 'number') {
      const server = servers.find(
        (element) => element.id === routers[index].kldNode
      );
      const db00Server = servers.find(
        (element) => element.id === routers[index].db00
      );
      const db01Server = servers.find(
        (element) => element.id === routers[index].db01
      );
      if (server && db00Server && db01Server) {
        const sshKey = keys.filter((element) => element.name === server.key)[0];
        const sshKeyDb00 = keys.filter(
          (element) => element.name === db00Server.key
        )[0];
        const sshKeyDb01 = keys.filter(
          (element) => element.name === db01Server.key
        )[0];

        if (!sshKey || !sshKeyDb00 || !sshKeyDb01) {
          setWarningMsg('SSH Public Key not found');
          return false;
        }
        const devicePaths = [];
        for (let i = 0; i < +server.disks; i++) {
          devicePaths.push(`/dev/nvme${i}n1`);
        }
        let txt = `   
    [hosts.kld-00]
    nixos_module="${server.id}"
    ipv4_address = "${server.IPv4}"
    ipv4_cidr = ${server.CIDR}
    ipv4_gateway = "${server.Gateway}"
    public_ssh_keys = [
      "${sshKey.key}"
     ]
    install_ssh_user = "${server.Username}"
    bitcoind_disks=[${routers[index].bitcoindDisks}]
   
    [hosts.db-00]
    nixos_module="${db00Server.id}"
    ipv4_address = "${db00Server.IPv4}"
    ipv4_cidr = ${db00Server.CIDR}
    ipv4_gateway = "${db00Server.Gateway}"
    public_ssh_keys = [
      "${sshKeyDb00.key}"
     ]
    install_ssh_user = "${db00Server.Username}"

    [hosts.db-01]
    nixos_module="${db01Server.id}"
    ipv4_address = "${db01Server.IPv4}"
    ipv4_cidr = ${db01Server.CIDR}
    ipv4_gateway = "${db01Server.Gateway}"
    public_ssh_keys = [
      "${sshKeyDb01.key}"
     ]
    install_ssh_user = "${db01Server.Username}"
    `;
        if (server.Provider === 'Latitude') txt += 'interface = "enp1s0f0"\n';
        const blob = new Blob([txt], { type: 'text/plain;charset=utf-8' });
        const a = document.createElement('a');
        a.download = 'kld.toml';
        a.href = URL.createObjectURL(blob);
        a.addEventListener('click', (e) => {
          setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
      } else {
        setWarningMsg('Something went wrong...');
        return false;
      }
    }
  };

  const mountServer = (index: number) => {
    setIndex(index);
    setSelectedRouter(routers[index].kldNode);
    setWarningMsg('');
    setOpenMountDialog(true);
  };

  // const handleChangeMountRouter = (router: IRouter, pool: string) => {
  //   const mr = mountedRouters;
  //   mr[pool] = router;
  //   localStorage.setItem('mountedRouters', JSON.stringify(mr));
  //   setmountedRouters({ ...mountedRouters, pool: router });
  // };

  const frameColor = theme.palette.text.primary;
  const arrowColor = theme.palette.primary.main;

  const isServersExists = !!localStorage.getItem('servers');
  const isKeysExists = !!localStorage.getItem('keys');
  const poolDialogData = [
    {
      id: 1,
      onClick: downloadConfigFile,
      title: 'Step 1',
      text: 'Config file',
      disabled: !(isServersExists && isKeysExists),
      icon: <FileLinkIcon frameColor={frameColor} arrowColor={arrowColor} />,
    },
  ];

  const modalData = [
    {
      id: 1,
      onClick: downloadKeyFile,
      text: 'Download updated config file',
      icon: <FileLinkIcon frameColor={frameColor} arrowColor={arrowColor} />,
      containerProps: {
        sx: {
          margin: '16px 0 0 0',
          width: '360px',
        },
      },
      textProps: {
        sx: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontFamily: "'Roboto', sans-serif",
          fontWeight: '400',
          lineHeight: 1,
          fontSize: '18px',
          flexGrow: 1,
        },
      },
    },
  ];

  const helperTextStyle = {
    sx: {
      fontWeight: '400',
      fontFamily: "'Roboto', sans-serif",
      fontSize: '14px',
      position: 'absolute',
      bottom: '-20px',
    },
  };

  const handleDialogClose = () => setOpenMountDialog(false);

  const customeTheme = getCustomThemeStyles(theme.palette.mode === 'dark');

  const handleResetClick = () => {
    setIsOpen(false);
    setResetModalIsShown(true);
  };

  const handleResetClose = () => {
    setResetModalIsShown(false);
    setIsOpen(true);
  };

  const handleMonitoringClick = () => {
    setIsOpen(true);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleConfirmClose = () => {
    setConfirmModalIsShown(false);
    setIsOpen(true);
  };

  const handleResendConfirmEmail = () => {
    if (email) {
      authService.resendConfirmMail(email).then((res) => {
        if (res.status === 'success') {
          setIsResent(true);
          let intervalId = setInterval(() => {
            setSecs((prev) => {
              if (prev === 0) {
                setIsResent(false);
                clearInterval(intervalId);
                return 60;
              } else {
                return prev - 1;
              }
            });
          }, 1000);
        }
      });
    }
  };

  const handleNewRouterClick = () => {
    setIsNewRouterOpen(true);
  };

  const handleNewRouterClose = () => {
    setIsNewRouterOpen(false);
  };

  const handleMonitoringClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = (
    values: IMonitoringValues,
    { resetForm }: FormikHelpers<IMonitoringValues>
  ) => {
    const networkId = 'mainnet';
    // localStorage.getItem("networkId");
    if (networkId) {
      authService
        .login({ ...values, protocol: `lightning-${networkId}` })
        .then(async (response) => {
          if (response && response.status === 'success') {
            setIsAuthorized(true);
            setEmail(values.email);
            const { token } = response.data;
            // localStorage.setItem("USER_ID", user_id);
            localStorage.setItem('TOKEN', token);
            resetForm();
          } else if (response && response.status === 'fail') {
            if (response.message === 'Your Email has not been verified') {
              setIsOpen(false);
              setConfirmModalIsShown(true);
              authService.resendConfirmMail(values.email).then((res) => {
                if (res.status === 'success') {
                  setIsResent(true);
                  let intervalId = setInterval(() => {
                    setSecs((prev) => {
                      if (prev === 0) {
                        setIsResent(false);
                        clearInterval(intervalId);
                        return 60;
                      } else {
                        return prev - 1;
                      }
                    });
                  }, 1000);
                }
              });
            }
            setError(
              'Incorrect email/password, please, check your credentials and try again'
            );
          } else {
            setError('Something went wrong...');
          }
        });
    }
  };

  return (
    <Box
      sx={{
        width: 1,
        marginInline: { lg: '7.6%', md: '7%', xs: 'auto' },
        marginRight: 0,
        position: 'relative',
      }}
    >
      <AddRouter handleClose={handleNewRouterClose} isOpen={isNewRouterOpen} />
      <SignUpConfirmDialog
        email={email}
        confirmModalIsShown={confirmModalIsShown}
        handleConfirmClose={handleConfirmClose}
        handleResendConfirmEmail={handleResendConfirmEmail}
        isResent={isResent}
        secs={secs}
      />
      <SignInForgotPassModal
        isOpen={resetModalIsShown}
        handleClose={handleResetClose}
      />
      <ModalWrapper
        isOpen={isOpen}
        handleClose={handleMonitoringClose}
        closeOnBlur
        withBackButton={false}
        dialogProps={{
          sx: {
            '& .MuiPaper-root': {
              maxWidth: { xs: '100%', md: '1152px' },
              bottom: '60px',
              left: '120px',
              borderRadius: '20px',
            },

            '& .MuiBackdrop-root': {
              backgroundColor: 'transparent',
              backdropFilter: 'blur(8px)',
            },
          },
        }}
      >
        <Formik
          initialValues={selfmonitoredInititalValue}
          onSubmit={handleSubmit}
          validationSchema={monitoredValidator}
          validateOnChange={false}
        >
          {({ errors }) => (
            <Box sx={{ display: 'flex' }}>
              <Box
                sx={{ padding: '56px 40px 56px 88px', position: 'relative' }}
              >
                <Box
                  sx={{
                    zIndex: 2,
                    overflow: 'hidden',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: 1,
                    height: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: 1,
                      height: 1,
                      backgroundColor: customTheme.colors.soonBg,
                      borderRadius: '20px 0px 0px 20px',
                      opacity: 0.75,
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      left: '-20%',
                      top: '41%',
                      height: '48px',
                      transform: 'rotate(-45deg)',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '24px',
                        lineHeight: 1,
                        textTransform: 'uppercase',
                        color: customTheme.colors.soon,
                        width: '106%',
                        textAlign: 'center',
                        backgroundColor: customTheme.colors.soonLineBg,
                        whiteSpace: 'nowrap',
                        padding: '12px',
                      }}
                    >
                      comming soon comming soon comming soon
                    </Typography>
                  </Box>
                </Box>
                <DialogTitle
                  sx={{
                    marginBlock: '14px 64px',
                    padding: '0',
                    textAlign: 'center',
                    fontWeight: 500,
                    lineHeight: '32px',
                    fontSize: '32px',
                  }}
                >
                  Self monitored
                </DialogTitle>
                <Box component={Form}>
                  <Field
                    name="mimir"
                    component={MuiTextField}
                    type="text"
                    label="IP of Mimir"
                    fullWidth
                    // error={!!errors.mimir}
                    // helperText={!!errors.mimir && errors.mimir}
                    sx={{
                      marginBottom: '32px',
                      fontSize: '16px',
                      fieldset: {
                        borderColor: `${customTheme.colors.serverLink} !important `,
                      },
                    }}
                    FormHelperTextProps={helperTextStyle}
                  />
                  <Field
                    name="loki"
                    component={MuiTextField}
                    type="text"
                    label="IP of Loki"
                    // error={!!errors.loki}
                    // helperText={!!errors.loki && errors.loki}
                    FormHelperTextProps={helperTextStyle}
                    fullWidth
                  />
                </Box>
                {modalData.map((dataEl) => (
                  <LinkButton key={dataEl.id} {...dataEl} />
                ))}
              </Box>
              <Divider
                orientation="vertical"
                variant="fullWidth"
                flexItem
                color={customeTheme.colors.primary}
              />
              {isAuthorized ? (
                <Box sx={{ padding: '56px 147px 93px' }}>
                  <Typography
                    variant="h3"
                    fontSize="32px"
                    lineHeight={1.5}
                    fontWeight={500}
                    marginBottom="16px"
                    textAlign="center"
                  >
                    kuutamo.cloud <br />
                    Hosted Monitoring
                  </Typography>
                  <Typography
                    margin={0}
                    color="primary"
                    fontSize="20px"
                    lineHeight={1}
                    fontFamily="Roboto, sans-serif"
                    textAlign="center"
                    marginBottom="133px"
                  >
                    tesetWalletId
                    {/* {wallet.accountId.length > 16
                    ? wallet.accountId.substring(0, 8) +
                      "..." +
                      wallet.accountId.substring(wallet.accountId.length - 8)
                    : wallet.accountId} */}
                  </Typography>
                  <Box
                    component={Link}
                    to="/data-modal"
                    sx={{
                      marginTop: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      columnGap: '16px',
                      textDecoration: 'none',
                    }}
                  >
                    <Button
                      type="submit"
                      sx={{
                        backgroundColor: 'primary.main',
                        textTransform: 'uppercase',
                        color: 'primary.light',
                        padding: '16px 32px',
                        lineHeight: 1,
                        fontSize: '15px',
                        boxShadow: customTheme.shadows.light,
                        transition: 'opacity 0.15s',
                        fontWeight: 400,

                        '&:hover': {
                          opacity: '0.9',
                          backgroundColor: 'primary.main',
                        },
                      }}
                    >
                      SETUP
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box sx={{ padding: '56px 88px 56px 40px' }}>
                  <DialogTitle
                    sx={{
                      marginBlock: '14px 24px',
                      padding: '0',
                      textAlign: 'center',
                      fontWeight: 500,
                      lineHeight: '32px',
                      fontSize: '32px',
                    }}
                  >
                    kuutamo.cloud
                  </DialogTitle>
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontWeight: 500,
                      lineHeight: 1,
                      fontSize: '24px',
                      marginBottom: '16px',
                      border: 0,
                    }}
                  >
                    Sign in
                  </Typography>
                  <Box component={Form}>
                    <Field
                      name="email"
                      component={MuiTextField}
                      type="email"
                      label="E-mail"
                      fullWidth
                      required
                      error={!!errors.email}
                      helperText={!!errors.email && errors.email}
                      sx={{ marginBottom: '32px', fontSize: '16px' }}
                      InputProps={{ name: 'email' }}
                      FormHelperTextProps={helperTextStyle}
                    />
                    <Field
                      name="password"
                      component={MuiTextField}
                      type={showPassword ? 'text' : 'password'}
                      label="Password"
                      fullWidth
                      required
                      error={!!errors.password}
                      helperText={!!errors.password && errors.password}
                      FormHelperTextProps={helperTextStyle}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              sx={{ color: 'text.secondary' }}
                            >
                              {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Typography
                        sx={{
                          marginTop: '16px',
                          color: 'text.secondary',
                          textDecoration: 'none',
                          fontSize: '14px',
                          lineHeight: 1,
                          width: 'fit-content',

                          '&:hover': {
                            textDecoration: 'underline',
                          },
                        }}
                        onClick={handleResetClick}
                      >
                        Forgot Password?
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        marginTop: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        columnGap: '16px',
                      }}
                    >
                      <Button
                        type="submit"
                        sx={{
                          backgroundColor: 'primary.main',
                          textTransform: 'uppercase',
                          color: 'primary.light',
                          padding: '16px 32px',
                          lineHeight: 1,
                          fontSize: '15px',
                          boxShadow: customTheme.shadows.light,
                          transition: 'opacity 0.15s',

                          '&:hover': {
                            opacity: '0.9',
                            backgroundColor: 'primary.main',
                          },
                        }}
                      >
                        log in
                      </Button>
                      <Typography>OR</Typography>
                      <Button
                        component={Link}
                        to="/signup"
                        sx={{
                          padding: '16px 32px',
                          lineHeight: 1,
                          fontSize: '15px',
                          border: 1,
                          borderColor: 'primary.main',
                          color: customTheme.colors.secondary,
                        }}
                      >
                        create account
                      </Button>
                    </Box>
                    {error && (
                      <Alert
                        sx={{
                          fontFamily: "'Roboto', sans-serif",
                          fontSize: '16px',
                          fontWeight: '500',
                        }}
                        severity="error"
                      >
                        {error}
                      </Alert>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Formik>
      </ModalWrapper>
      <SnackbarAlert msg={warningMsg} setMsg={setWarningMsg} severity="error" />
      <ChooseDialog
        isOpen={openMountDialog}
        title="Follow the steps"
        handleClose={handleDialogClose}
        data={poolDialogData}
        dialogProps={{
          PaperProps: {
            sx: {
              '@media (max-height:800px)': {
                top: '95px',
              },
            },
          },
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{ fontSize: '48px', marginBottom: '8px' }}
          lineHeight={1}
          component="h1"
          variant="h4"
          align="left"
        >
          List of routers
        </Typography>
        <Button
          onClick={handleNewRouterClick}
          variant="text"
          sx={{
            padding: '16px 32px',
            boxShadow: '0px 0px 8px rgb(0 33 71 / 10%)',
            color: theme.palette.mode === 'dark' ? '#FEFEFF' : '#002147',
            backgroundColor:
              theme.palette.mode === 'dark' ? '#151C2B' : '#FEFEFF',
            border: 'inherit',
            fontSize: '15px',
            margin: '16px 4px 16px 8px',
          }}
        >
          <img
            style={{ marginRight: '18px' }}
            src={'/icons/addsquare-' + theme.palette.mode + '.png'}
            alt="add"
          />
          New Router
        </Button>
      </Box>
      <Table aria-label="routers">
        <TableHead>
          <TableRow>
            <TableCell>Node Aliases</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {routers &&
            routers.map((router, index) => (
              <TableRow
                key={index}
                sx={{
                  height: '63px',
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell scope="row">{router.aliasNode}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        marginRight: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        columnGap: '8px',
                      }}
                    >
                      {/* <FormControl sx={{ minWidth: 173 }} size="small">
                        <Select
                          value={
                            mountedRouters[router.owner_id]
                              ? mountedRouters[router.owner_id]
                              : ''
                          }
                          placeholder="server"
                          onChange={(event) =>
                            handleChangeMountRouter(
                              event.target.value,
                              router.owner_id
                            )
                          }
                          displayEmpty
                          IconComponent={(props) => <SelectArrow {...props} />}
                          sx={{
                            borderRadius: '5px !important',

                            '& .MuiInputBase-input': {
                              height: '16px',
                              padding: '4px 30px 4px 8px',
                              fontSize: '16px',
                              lineHeight: 1,
                              display: 'flex',
                              alignItems: 'center',
                            },

                            '& .MuiSelect-icon': {
                              top: 0,
                              bottom: 0,
                              right: '10px',
                              marginBlock: 'auto',
                              transform: 'rotate(90deg)',
                              transition: '0.15s',
                            },
                            '& .MuiSelect-iconOpen': {
                              transform: 'rotate(0deg)',
                            },
                          }}
                        >
                          <StyledMenuItem value="">Server</StyledMenuItem>
                          {/* TODO: filter choosen values */}
                      {/* {servers.map((s) => (
                            <StyledMenuItem value={s.id} key={s.id}>
                              {s.id}
                            </StyledMenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                      <FormControl size="small">
                        <Button
                          variant="contained"
                          onClick={() => mountServer(index)}
                          // disabled={!mountedRouters[router.kldNode]}
                          sx={{
                            backgroundColor: 'primary.main',
                            color: 'primary.light',
                            border: 0,
                            fontWeight: 400,

                            '&:hover': {
                              border: 0,
                            },
                          }}
                        >
                          Manage
                        </Button>
                      </FormControl>
                    </Box>
                    <Button
                      variant="outlined"
                      onClick={handleMonitoringClick}
                      sx={{
                        borderColor: customTheme.colors.btn,
                        backgroundColor: 'primary.main',
                        color: 'primary.light',
                        fontWeight: 400,
                        border: 0,
                        marginRight: '40px',
                        '&:hover': {
                          backgroundColor: customTheme.colors.btn,
                          border: 0,
                        },
                      }}
                    >
                      Monitoring
                    </Button>
                    {/* <Box component={Link} to="/run-modal">
                      <Button
                        variant="outlined"
                        sx={{
                          backgroundColor: 'primary.main',
                          color: 'primary.light',
                          fontWeight: 400,
                          border: 0,
                          '&:hover': {
                            backgroundColor: customTheme.colors.btn,
                            border: 0,
                          },
                        }}
                      >
                        kld-ui
                      </Button>
                    </Box> */}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {/* {!myRoutersIsReady ? <LinearProgress /> : null} */}
    </Box>
  );
};

export default RoutersPage;

